import { createCookie, getCookie } from '../cookie/core__cookies';
import PubSub from '../pubsub/core__pubsub';
import { PubsubEvents } from '../pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import project from '../../project';

/**
 * Method to get odds Format
 */
const getOddsFormat = () => {
  const OddsFormat = getCookie('selectedOdds');
  return OddsFormat;
};

/**
 * Method to set odds Format
 */
const setOddsFormat = oddsType => {
  const cookieValue = getCookie('selectedOdds');
  if (cookieValue === false) {
    createCookie('selectedOdds', oddsType.toLowerCase());
  } else {
    PubSub.emit(PubsubEvents.oddsValue, oddsType.toLowerCase());
    createCookie('selectedOdds', oddsType.toLowerCase());
  }
  const OddsFormat = oddsType.toLowerCase();
  return OddsFormat;
};
/**
 * Method to round off decimal odds
 */
/**
 *
 * @param {String} priceDecimal
 * @returns rounded off odd upto 2 decimal places
 */
const parseDecimalPlaces = priceDecimal => {
  if (!isNaN(priceDecimal)) {
    if (priceDecimal >= 1.01) {
      const priceDecimalRounded = Math.round(1000 * priceDecimal) / 1000;
      const priceDecimalFloat = parseFloat(priceDecimalRounded);
      var patt = /\.\d{3,}/g;

      if (patt.test(priceDecimalFloat)) {
        var regEx = new RegExp(/\d+\.\d{2}/);

        return parseFloat(priceDecimalFloat.toString().match(regEx)[0]);
      } else {
        return priceDecimalFloat.toFixed(2);
      }
    } else {
      const priceDecimalFloat = parseFloat(priceDecimal);
      // if priceDecimal < 1.01 we need to round and format to a maximum of 4 decimal places (and minmum 2 if equal/rounds to 1)
      const shortPriceDecimal = project.oddsFormat?.[0]?.twoDigitDecimal
        ? parseFloat(priceDecimalFloat.toFixed(2))
        : parseFloat(priceDecimalFloat.toFixed(4));

      return shortPriceDecimal === 1
        ? shortPriceDecimal.toFixed(2)
        : shortPriceDecimal;
    }
  } else if (priceDecimal === 'SP') {
    return priceDecimal;
  }
};

const parseDecimalPlacesPotReturn = priceDecimal => {
  const currencyValue =
    FSBCustomerSession.getSession()?.currencyCode || project.defaultCurrency;
  const currency = project?.betslipCurrencies?.find(
    currency => currency.currency === currencyValue
  );
  if (!isNaN(priceDecimal)) {
    priceDecimal = Math.round(1000 * priceDecimal) / 1000;
  }
  var patt = /\.\d{3,}/g;
  var regEx = new RegExp(/\d+\.\d{2}/);

  if (currency && currency.roudingMethod) {
    if (priceDecimal && !isNaN(priceDecimal) && priceDecimal >= 1.01) {
      if (patt.test(priceDecimal)) {
        return parseFloat(priceDecimal.toString().match(regEx)[0]).toFixed(0);
      } else {
        return parseFloat(priceDecimal).toFixed(0);
      }
    } else {
      return parseFloat(priceDecimal).toFixed(0);
    }
  } else {
    if (priceDecimal && !isNaN(priceDecimal) && priceDecimal >= 1.01) {
      if (patt.test(priceDecimal)) {
        return parseFloat(priceDecimal.toString().match(regEx)[0]);
      } else {
        return parseFloat(priceDecimal).toFixed(2);
      }
    } else {
      return parseFloat(priceDecimal).toFixed(2);
    }
  }
};

export {
  getOddsFormat,
  setOddsFormat,
  parseDecimalPlaces,
  parseDecimalPlacesPotReturn,
};
