export const BACKGROUND = {
  standard: '#111111',
  background: '#F5F7FA',
  backgroundDivider: '#E2E4E9',
  oddsButton: '#ECEDF8',
  oddsButtonSelected: '#DBDDF6',
  surface: '#fff',
  surfaceDivider: '#373E551A',
  surfaceOverlay: '#ECEDF8',
  surfaceVarient: '#ECEDF8',
};

export const BRAND = {
  primary: '#111FA3',
  primaryD: '#0E1982',
  primaryL: '#A5AADC',
  secondaryD: '#AF160F',
  secondary: '#DB1B13',
  secondaryL: '#F1A8A5',
  tertiaryD: '#b4c9d9',
  tertiary: '#dae4ec',
  tertiaryL: '#f2f6f8',
  favourite: '#fdd835',
};

export const GREYS = {
  black: '#000000de', // 87% opacity
  silverD: '#545454',
  silver: '#909090',
  doveD: '#e0e0e0',
  dove: '#ededed',
  doveL: '#ECEDF8',
  white: '#ffffff',
};

export const FUNCTIONAL = {
  positiveD: '#2B8F65',
  positive: '#36b37e',
  positiveL: '#E1F4EC',
  negativeD: '#AF160F',
  negative: '#ff3939',
  negativeL: '#FFE1E1',
  guideD: '#3D9DCC',
  guide: '#4cc4ff',
  guideL: '#E4F6FF',
  success: '#36b37e',
  warningD: '#CC701A',
  warning: '#ff8c21',
  warningL: '#FFEEDE',
  error: '#ff3939',
};

export const FONT = {
  dark: '#000000de', // 87% opacity
  darkMute: '#909090',
  light: '#ffffff',
  lightMute: '#e0e0e0',
  primary: '#111FA3',
  secondary: '#ffc500',
  blue: '#111FA3',
  white: {
    disabled: '#ffffff61', // 38% opacity
    label: '#ffffffde', // 87% opacity
    mid: '#ffffff99', // 60% opacity
    standard: '#fff',
  },
  black: {
    disabled: '#00000061', // 38% opacity
    label: '#000000de', // 87% opacity
    mid: '#00000099', // 60% opacity
    standard: '#000000',
  },
};

export const SUPPLEMENTARY = {
  yellowD: '#C19F26',
  yellow: '#F1C72F',
  yellowL: '#FAEAB0',
  casinoD: '#cc5e00',
  casino: '#ff7600',
  casinoL: '#ff9133',
  poolD: '#8e1aad',
  pool: '#b221d8',
  poolL: '#c34ae3',
  black: '#000000',
};

export const SHADOW = `0 0 0 1px ${GREYS.doveD}`;

export const BGI =
  'linear-gradient(-14deg,rgba(84, 219, 213, 0) 0%,#2ab4ab 100%)';

export const Q_BGI =
  'linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, #000000 100%)';

export const CARD = {
  display: 'flex',
  flexDirection: 'column',
  background: GREYS.white,
  boxShadow: SHADOW,
  borderRadius: 4,
  marginBottom: 16,
};

export const EX_COLORS = {
  brandYellow: '#F1C72F',
  brandWhitey: '#F2F6F8',
};

export const BK_COLORS = {
  yellow: '#F1C72F',
  aliceBlue: '#ECEDF8',
  blue: '#111FA3',
  blueOther: '#0057a3',
  darkblue: '#0E1982',
  red: '#DB1B13',
  aliceBlue10: 'rgba(55, 62, 85, 0.1)',
  aliceBlue08: 'rgba(17, 31, 163, 0.08)',
  EVENTS: {
    bodySection: `rgba(17, 31, 163, 0.08)`,
    bodyHeader: `#fff`,
    shadow: `0 0 0 1px rgba(55, 62, 85, 0.1)`,
    categoryShadow: `rgba(17, 31, 163, 0.08)`,
    gradient:
      'linear-gradient(0deg, rgba(17, 31, 163, 0.08), rgba(17, 31, 163, 0.08)), #ECEDF8',
  },
};

export const SCROLL_CAROUSEL_PADDING = '10px 1px';

export const INFO_BOX_BG = '#ebf8ff';

export const CONTACT_DETAIL = 'rgba(0, 0, 0, 0.60)';
