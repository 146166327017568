export const GLOBAL_CONSTANTS = {
  RETURN_CODE: { RULE_EXCEPTION: 'RULE_EXCEPTION', SUCCESS: 'SUCCESS' },
  ATHLETICS: 'ATHLETICS',
  OLYMPICS: 'OLYMPICS',
  USER_ICON: 'USER',
  PERSONAL: 'personal',
  ACCOUNT: 'account',
  DATA_VERIFICATION: 'dataVerification',
  COMPLETE: 'complete',
  NORMAL: 'normal',
  MARKET: 'market',
  REVERSE: 'REVERSE',
  EVENTSAPP: 'EventsApp',
  EVENTSOUTRIGHTSAPP: 'EventsOutrightsApp',
  TOPLEAGUESAPP: 'TopLeaguesApp',
  EVENTHEADERAPP: 'EventHeaderApp',
  SELECTEDGAME: 'selectedGame',
  TOPLISTS: 'Top Lists',
  BACKGROUNDIMAGE: 'backgroundImage',
  SPORTS: 'SPORTS',
  VIRTUALS: 'VIRTUALS',
  SHOPS: 'SHOPS',
  CASINO: 'CASINO',
  PROMOTIONS: 'PROMOTIONS',
  SOCCER: 'SOCCER',
  BANDY: 'BANDY',
  OPTA: 'OPTA',
  NUMBER: 'number',
  CANCEL: 'CANCEL',
  CURRENT: 'current',
  NEW: 'new',
  REFERENCE: 'reference',
  LINE1: 'line1',
  LINE2: 'line2',
  PLACE: 'place',
  COUNTRYCODE: 'countryCode',
  POSTALCODE: 'postalCode',
  END: 'END',
  DECIMAL: 'decimal',
  FRACTIONAL: 'fractional',
  AMERICAN: 'american',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  WITHDRAW_HISTORY: 'Withdrawal History',
  LOST: 'Lost',
  TRICAST: 'Tricast',
  FORECAST: 'Forecast',
  DEFAULT: 'default',
  UNAUTHORIZED: 'Unauthorized',
  DELETESUSPENDED: 'deletesuspended',
  SUSPENDED: 'SUSPENDED',
  LOWBALANCE: 'low-balance',
  ACCEPT: 'accept',
  DECLINE: 'decline',
  CASTORDER: 'castorder',
  SINGLE: 'single',
  Single_Goal: 'Single Goal',
  SINGLEBETSLIP: 'Single',
  CREATEABET: 'Create A Bet',
  FOOTBALL: 'Football',
  SOCCERLIST: 'Soccer',
  CATEGORY: 'category',
  COUNTRY: 'country',
  DATE: 'date',
  STRING: 'string',
  INPLAY: 'IN_PLAY',
  IN_PLAY_ROUTE: '/all-in-play/',
  LIVE: 'LIVE',
  BASEBALL: 'BASEBALL',
  AMERICAN_FOOTBALL: 'AMERICAN_FOOTBALL',
  STATIC: 'static',
  COMPLETED: 'COMPLETED',
  TOPLISTSCASINO: 'top-lists',
  PROMOS: 'PROMOS',
  FROM_BET_SLIP: 'frombetslip',
  FROM_CASINO: 'fromCasino',
  FROM_FORGOT: 'fromforgot',
  BACKSPACE: 'backspace',
  OUT: 'OUT',
  ACTIVE: 'ACTIVE',
  HOME: 'home',
  AWAY: 'away',
  GOAL_SCORER: 'goalscorer',
  SHOW_NUM_PAD: 'shownumpad',
  SPACE: 'space',
  CLEAR: 'clear',
  DONE: 'done',
  PRICE: 'PRICE',
  TEAM: 'team',
  ALL: 'all',
  NO_DATA: 'noData',
  LIGHT: 'light',
  RED: 'red',
  REALEX: 'REALEX',
  DYNAMIC: 'dynamic',
  EVENT_STATE: {
    SCHEDULED: 'SCHEDULED',
    IN_PLAY: 'IN_PLAY',
  },
  PERFORM: 'perform',
  HORSES: 'HORSES',
  WATCH: 'Watch',
  REGISTRATION_FINISHED: 'Registration finished',
  SUCCESSFULL_LOGIN: 'Successful Login',
  REPEAT_DEPOSIT: 'Repeat Deposit',
  FIRST_DEPOSIT: 'First Deposit',
  USERNAME: 'username',
  REGISTRATION_STARTED: 'Registration Started',
  SCHEDULED: 'SCHEDULED',
  BREAK_TIME: 'Break Time',
  REGISTRATION_FAILED: 'Registration failed',
  REGISTRATION_SUCCESSFULL: 'Registration successfull',
  REGISTRATION_STATUS: 'Registration status',
  CUSTOMER_ID: 'customer Id',
  CLOSED_EYE: 'CLOSED_EYE',
  OPEN_EYE: 'OPEN_EYE',
  PASSWORD: 'password',
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
  S_CASINO: 'casino',
  S_LIVE_CASINO: 'live-casino',
  WON: 'Won',
  N_A: 'N/A',
  DEBIT_CREDIT_CARD: 'Debit/Credit Card',
  UTILITY_BILL: 'Utility Bill',
  PENDING_S: 'Pending',
  NOT_PLACED: 'Not Placed',
  VOID: 'Void',
  NON_RUNNER: 'Non Runner',
  FOOTBALL_REF: 'FOOTBALL',
  BASKETBALL_REF: 'BASKETBALL',
  BOXING: 'Boxing',
  MATCH_WINNER: 'Match Winner',
  FIGHT_WINNER: 'Fight Winner',
  TOP: 'top',
  UNAVIALABLE_PROMO: 'Unavailable promotion',
  TOP_LEAGUES: 'Top Leagues',
  REST_OF_WORLD: 'Rest of world',
  NR: 'NR',
  SP: 'SP',
  DOGS: 'dogs',
  FUTURE_RACES: 'FUTURE_RACES',
  RACING_LEGENDS: 'RACING_LEGENDS',
  UFAV: 'UFAV',
  UNNAMED_FAVOURITE: 'Unnamed Favourite',
  EFPTP: 'EFPTP',
  SEW: 'SEW',
  EEW: 'EEW',
  ODDS_INCREASE: 'increase',
  ODDS_DECREASE: 'decrease',
  WEEKLY_S: 'Weekly',
  WEEKLY_L: 'WEEKLY',
  DAILY_S: 'Daily',
  DAILY_L: 'DAILY',
  MONTHLY_L: 'MONTHLY',
  MONTHLY_S: 'Monthly',
  SECOND_S: 'Second',
  THIRD_S: 'Third',
  FOURTH_S: 'Fourth',
  CLOCK: 'CLOCK',
  UNEMPLOYED_S: 'unemployed',
  MOBILE_NUMBER: 'mobileNumber',
  PLAYER_NUMBER: 'player number',
  EMPLOYER_BUSINESS_NUMBER: 'employer buisness number',
  FULL_TIME: 'fullTime',
  SUCCESS_L: 'Success',
  PREFERRED_NAME: 'preferredName',
  STR: 'str',
  ADDRESS: 'address',
  HOUSE_NUMBER: 'houseNumber',
  CITY: 'city',
  STATE: 'state',
  CITIZENSHIP: 'citizenship',
  PLAYER_NUMBER_S: 'playerNumber',
  NUM_S: 'num',
  EMPLOYMENT_STATUS: 'employmentStatus',
  EMPLOYER_NAME: 'employerName',
  EMPLOYER_OCCUPATION: 'employerOccupation',
  EMPLOYER_ADDRESS: 'employerAddress',
  EMPLOYER_HOUSE_NUMBER: 'employerHouseNumber',
  EMPLOYER_POSTALCODE: 'employerPostalCode',
  EMPLOYER_CITY: 'employerCity',
  EMPLOYER_STATE: 'employerState',
  EMPLOYER_BUSINESS_NUM: 'employerBusinessNumber',
  ERROR: 'error',
  NULL: 'null',
  CONTACT_DETAILS: 'Contact Details',
  REG_ACTIVE: 'active',
  SELECT_COUNTRY_CODE: 'Select country Code',
  CURRENCY_CODE: 'currencyCode',
  SPORTSBOOK: 'sportsbook',
  IN_PLAY: 'In-Play',
  MULTIFACTOR_AUTHENTICATION_CODE: 'mfaCode',
  MFA_TIMEOUT: 'MFA_TIMEOUT',
  MFA_NOT_MATCH: 'MFA_NOT_MATCH',
  INTERAC_TRANSFER: 'PAYSAFE-INTERAC_ETRANSFER',
  PAYSAFE_CARD: 'PAYSAFE-CARD',
  TITLE: 'title',
  BETTING_POOLS: 'betting-pools',
  FOOTBALL_ICON: 'icon-football',
  CONFIRM: 'confirmPassword',
  PLAY_NOW_L: 'PLAY NOW',
  PROMOTIONS_S: 'Promotions',
  HOT_GAMES_C: 'HOT GAMES',
  EXCLUSIVE_C: 'EXCLUSIVE',
  NEW_C: 'NEW',
  LEVEL_ZERO: 'LEVEL_ZERO',
  TABLE_TENNIS_REF: 'TABLE_TENNIS',
  TENNIS_REF: 'TENNIS',
  DARTS_REF: 'DARTS',
  CRICKET_REF: 'CRICKET',
  VOLLEYBALL_REF: 'VOLLEYBALL',
  FIRST: 'First',
  IFRAME_SIZE: 'IFRAME_SIZE',
  IFRAME_LANDSCAPE_SIZE: 'IFRAME_LANDSCAPE_SIZE',
  IFRAME_NAVIGATION: 'IFRAME_NAVIGATION',
  EMPLOYED: 'employed',
  DIALING_CODE: 'dialingCode',
  ADDRESS_TWO: 'address2',
  EMPLOYER_ADDRESS_TWO: 'employerAddress2',
  EACHWAY: 'EACHWAY',
  STATSCORE_ERROR: 'statscore.livematchpro.error',
  MIDDLE_NAME: 'middleName',
  RETIRED: 'retired',
  STUDENT: 'student',
  UNEMPLOYED: 'unemployed',
  CANADA: 'canada',
  FAVOURITE: 'favourite',
  AUTH: 'auth',
  NOAUTH: 'noauth',
  CORNERS: 'corners',
  CASH_OUT: 'CASH_OUT',
  DOGS_REF: 'DOGS',
  HORSES_REF: 'HORSES',
  NULL_ODDS: '0',
  EVEN_ODDS: 'evs',
  DAY: '1 Day',
  WEEK: '7 Days',
  MONTH: '30 Days',
  BANKING: 'banking',
  JAPANESE: 'ja',
  GOLF: 'Golf',
  DRAW: 'draw',
  APPROVED: 'APPROVED',
  VERIFIED: 'VERIFIED',
  EN: 'en',
  OTHER: 'Other',
  SETTLED: 'SETTLED',
  SHOTS_ON_TARGET: 'Shots on Target',
  PLAYER_OVER_FOULS: 'Player over Fouls',
  PLAYER_OVER_SHOTS: 'Player over Shots',
  DECLINED: 'DECLINED',
  VIRTUAL: 'VIRTUAL',
  FANTASY: 'FANTASY2',
  USER_DATA: 'userData',
  CAST: 'CAST',
  SPREAD: 'Spread',
  EXLCUDE_BETS: [
    'Patent',
    'Trixie',
    'Yankee',
    'Super Yankee',
    'Heinz',
    'Super Heinz',
    'Goliath',
    'Lucky 15',
    'Lucky 31',
    'Lucky 63',
  ],
  EMAILBLACKLISTED: 'emailBlacklisted',
  SMSBLACKLISTED: 'smsBlacklisted',
  TICKMARK_CIRCLE: 'TICKMARK_CIRCLE',
  OTHER_S: 'other',
  BETSLIP: 'betslip',
  HAMBURGER_MENU: 'hamburgermenu',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  MYBETS: 'mybets',
  MYACCOUNT: 'myaccount',
  LOW_BALANCE_ALERT: 'lowBalanceAlerted',
  RESPONSIBLE_GAMING_PAGE: 'Responsible gaming page',
  GAMBLING_CONTROLS: 'gambling controls',
  CONTACT_US: 'contact us',
  SUCCESS: 'Success',
  PENDING_SS: 'pending',
  DECLINED_S: 'declined',
  INSUFFICIENT_BALANCE: 'api.error.balance',
  REALITY_CHECK_PORTAL: 'realityCheckPortal',
  BET_BUILDER: 'Bet Builder',
  BREAKPOINT_L: 'BREAKPOINT_L',
  BREAKPOINT_XL: 'BREAKPOINT_XL',
  BBB: 'BBB',
};
export const GLOBAL_PATH = {
  SEARCH: '/search/',
  REGISTER: '/register/',
  CONTACT_US: '/contact-us/',
  QUICK_DEPOSIT: '/quick-deposit-cash/?missingAmount=',
  VIRTUAL: '/virtual/',
  EVENT_COUNT_BASE: '/fsb-api-rest/bet/category/eventCount.fsb',
  EVENT_DATA_BASE: '/fsb-api-rest/bet/category',
  RESPONSIBLE_GAMING: '/responsible-gambling/',
  RESPONSIBLE_GAMING_CAD: '/responsible-gaming/',
  FORGOT_PASSWORD: '/forgot-password/',
  SELF_EXCLUDE: '/self-exclude/',
  COOKIES_POLICY: '/cookie-policy/',
  TAC: '/terms-and-conditions/',
  PRIVACY_POLICY: '/privacy-policy/',
  SPORTS_BOOK: '/sportsbook/',
  CASINO: '/casino/',
  CUSTOMER_EP: '/fsb-api-rest/customer.fsb',
  LOSS_LIMITS: '/my-account/loss-limits/',
  DEPOSIT_LIMITS: '/my-account/deposit-limits/',
  NET_DEPOSIT_LIMITS: '/my-account/net-deposit-limits/',
  PRODUCT_RESTRICTION: '/my-account/product-restriction/',
  FAQ_SUPPORT: '/faq-support/',
  KYC: '/my-account/kyc/',
  LEGACY_KYC: '/my-account/?tab=kyc',
  PROMOTIONS: '/promotions/',
  DEPOSIT_KM: '/my-account/banking/deposit/',
  DEPOSIT_NOW: '/my-account/deposit/',
  WITHDRAW_KM: '/my-account/banking/withdraw/',
  STAKE_LIMIT: '/my-account/stake-limit/',
  GAMBLING_CONTROL: '/my-account/?tab=gambling',
  DEFAULT_SILK: 'https://silks.fsbtech.com/default_silk.png',
};
