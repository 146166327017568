import PubSub from '../pubsub/core__pubsub';
import WSBroadCast from './core__ws_broadcast';
import project from '../../project';
import { getCookie } from 'Services/cookie/core__cookies';

// const config =
// {
//     wssid: $('body').data('sid') || false,
//     wssportsbundle: $('body').data('sportsbundle') || false,
//     wslangids: $('body').data('languageswl') || [],
//     wsbundleenabled: $('body').data('enablesportsbundle') || false,
//     languageid: parseInt(Cookies.get('languageId')) || false,
// };

class WSSubscriber {
  constructor() {
    this.cometdClient = false;
    this.subscribedMarkets = new Map(); //markets subscribed
    this.subscribedEvents = new Map(); //events subscribed
    this.pendingMarketSubs = [];
    this.pendingEventsSubs = [];

    this.subscribedBalance = new Map(); //
    this.pendingBalanceSubs = new Set();

    this.listeners(); //pubsub subs
  }

  start(cometdClient) {
    this.cometdClient = cometdClient;
    if (this.subscribedMarkets.size !== 0) {
      this.subscribeMarkets(Array.from(this.subscribedMarkets.keys()), true);
    }
    if (this.subscribedEvents.size !== 0) {
      this.subscribeEvents(Array.from(this.subscribedEvents.keys()), true);
    }
    for (const e of this.pendingEventsSubs) this.subscribeEvents(e);
    for (const m of this.pendingMarketSubs) this.subscribeMarkets(m);
    this.pendingEventsSubs = [];
    this.processEventUpdate = [];
    const pendingBalanceSubsConvertedToArr = Array.from(
      this.pendingBalanceSubs
    );
    if (pendingBalanceSubsConvertedToArr.length !== 0)
      for (const b of pendingBalanceSubsConvertedToArr)
        this.subscribeBalance(b);
  }

  listeners() {
    PubSub.listen('ws:sub:event', events => this.subscribeEvents(events || [])); //global for all events
    PubSub.listen('ws:sub:bs:markets', markets =>
      this.subscribeEvents(markets || [])
    ); //global for all betslip selections
    PubSub.listen('ws:sub:market', events =>
      this.subscribeMarkets(events || [])
    ); //global for all markets subscriptions

    PubSub.listen('ws:sub:balance', token =>
      this.subscribeBalance(token || [])
    ); //global for all balance subscriptions
  }

  subscribeEvents(events, bypassExisting = false) {
    events = Array.isArray(events) ? events : [events];

    if (!this.cometdClient) return this.pendingEventsSubs.push(events);
    if (!events.length) return; //nothing to do

    const toSubscribe =
      (!bypassExisting &&
        events.filter(
          event => event.sourceKey
          // && !this.subscribedEvents.get(event.sourceKey)
        )) ||
      events;

    if (!toSubscribe.length) return; //nothing to do

    this.cometdClient.batch(() =>
      toSubscribe.forEach(evt => this.subscribeByEvent(evt))
    );
  }

  subscribeByEvent(event) {
    const { categoryRef = false, providerRef = false, sourceKey } = event;

    //one of the mandatory fields are missing
    if (!categoryRef || !sourceKey) return;

    //channel string
    const channel =
      categoryRef === 'MMA'
        ? '/MixedMartialArts/' + sourceKey
        : '/' + categoryRef + '/' + sourceKey;

    this.cometdClient.subscribe(channel, message => {
      const getCachedEventData = this.subscribedEvents.get(
        message.data.sourceKey
      );
      if (getCachedEventData)
        message.data.providerRef = getCachedEventData.providerRef;
      WSBroadCast.processEventUpdate(message.data);
    });

    this.cometdClient.publish('/service/' + categoryRef, {
      sourceKey: sourceKey,
    });

    if (providerRef === 'OPTA' && categoryRef === 'SOCCER')
      this.cometdClient.subscribe(channel + '/data', message => {
        message.data.providerRef = 'OPTA';
        WSBroadCast.processEventUpdate(message);
      });

    this.subscribedEvents.set(sourceKey, event); //save sourcekey on map to avoid re-subscribing
  }

  subscribeMarkets(markets, bypassExisting = false) {
    markets = Array.isArray(markets)
      ? markets
      : typeof markets === 'number'
      ? [markets]
      : markets;

    if (!this.cometdClient) return this.pendingMarketSubs.push(markets);
    if (!markets.length) return; //nothing to do

    const toSubscribe =
      (!bypassExisting &&
        markets.filter(marketId => !this.subscribedMarkets.get(marketId))) ||
      markets;

    if (!toSubscribe.length) return; //nothing to do

    this.cometdClient.batch(() =>
      toSubscribe.forEach(marketId => this.subscribeByMarktId(marketId))
    );
  }

  subscribeByMarktId(marketId) {
    const getLanguageId = getCookie('languageId');
    const bundle = project.sportsBundle?.name;
    const siteId = project.supersiteId;
    if (
      project.translatedMarketSubscription &&
      bundle &&
      siteId &&
      getLanguageId
    ) {
      // Market channel subscription to get market translations
      this.cometdClient.subscribe(
        `/fsbchannel/${siteId}/${bundle}/${Number(getLanguageId)}/${marketId}`,
        message => {
          WSBroadCast.processMarketUpdate(message);
        }
      );
    } else {
      this.cometdClient.subscribe(`/fsbchannel/${marketId}`, message => {
        WSBroadCast.processMarketUpdate(message);
      });
    }
    this.subscribedMarkets.set(marketId, true); //save sourcekey on map to avoid re-subscribing
  }

  subscribeBalance(token) {
    if (!this.cometdClient) return this.pendingBalanceSubs.add(token);
    if (!token) return; //nothing to do
    this.subscribeByBalanceId(token);
  }

  subscribeByBalanceId(sessionToken) {
    this.cometdClient.subscribe(`/customer/${sessionToken}`, message =>
      WSBroadCast.processBalanceUpdate(message)
    );
    this.subscribedBalance.set(sessionToken, true); //save sourcekey on map to avoid re-subscribing
  }
}

const singleton = new WSSubscriber();
export default singleton;
