import { timeFormats } from 'Services/global/core__time-formats';
import {
  detectMob,
  isAndroidApp,
} from 'Services/globalfunctions/core__global-functions';

timeFormats.accounts.primary = 'YYYY/MM/DD HH:mm:ss';
timeFormats.bets.primary = 'YYYY/MM/DD';
timeFormats.lotto.tertiary = 'YYYY/MM/DD HH:mm:ss';
timeFormats.lotto.history = 'YYYY/MM/DD HH:mm:ss';

export default {
  home: '/sportsbook/',
  groupRef: 'kms',
  clientIdDesktopWeb: 'kmsweb',
  clientIdMobileWeb: 'kmsmobile',
  clientIdMobileApp: 'kmsandroid',
  get clientId() {
    try {
      const isMobile = detectMob();
      const clientId = isAndroidApp()
        ? this.clientIdMobileApp
        : isMobile
        ? this.clientIdMobileWeb
        : this.clientIdDesktopWeb;
      return clientId;
    } catch (e) {
      return this.clientIdDesktopWeb;
    }
  },
  clientSecretDesktopWeb: 'gTF3yk5k2Q',
  clientSecretMobileWeb: 'nGys9YaZWw',
  clientSecretMobileApp: 'BvfdQhXNmX',
  get clientSecret() {
    try {
      const isMobile = detectMob();
      const clientSecret = isAndroidApp()
        ? this.clientSecretMobileApp
        : isMobile
        ? this.clientSecretMobileWeb
        : this.clientSecretDesktopWeb;
      return clientSecret;
    } catch (e) {
      return this.clientSecretDesktopWeb;
    }
  },
  defaultCurrency: [{ value: 'ZAR', label: 'ZAR' }],
  additionalGamblingTabs: ['Close Account', 'PRODUCT_RESTRICTION'],
  defaultCountryCode: '+44',
  distributedBundle: true,
  defaultOddsType: 'decimal',
  bundle: {
    name: 'react,kms',
    languanges: [
      { id: 1, value: 'en' },
      { id: 3, value: 'de' },
    ],
  },
  sportsBundle: {
    id: 20,
    name: 'sports',
    languanges: [
      { id: 1, value: 'en' },
      { id: 3, value: 'de' },
    ],
  },
  staticPath: {
    // base path
    basePath: 'https://assets.fsbtech.com/',
    // bucket name should be react
    bucketReact: 'react/',
    bucketImages: 'images/',
    // folder name
    folderIcon: 'icons/',
    folderMisc: 'misc/',
    folderFlags: 'flags/',
    folderPayments: 'payments/',
    folderVirtuals: 'virtuals/',
  },
  casino: {
    assetsUrl: 'https://assets.fsbtech.com/images/casino',
  },
  timeFormats,
  currency: 'ZAR',
  countryDrop: [
    {
      value: 'GB',
      label: 'UNITED KINGDOM',
    },
  ],
  imagesUrl: 'https://assets.fsbtech.com/react/quinnbet/icons/icon-',
  appsWithoutCMS: ['<fsbapp id="3" data-appid="99" data-app="TCApp"></fsbapp>'],
  utmBtagTracking: true,
  accountRedirectUrls: {
    banking: '/my-account/banking/',
    deposit: '/my-account/banking/deposit/',
    // myBets:             '/my-account/my-bets/',
    // freeBetsAndBonuses: '/my-account/free-bets-and-bonuses/',
    history: '/my-account/history/',
    // gamblingControls:   '/my-account/gambling-controls/',
    kyc: '/my-account/fica/',
    withdraw: '/my-account/banking/withdraw/',
    withdrawfica: '/my-account/banking/withdraw/verify/',
    // myAccount:          '/my-account/account/',
  },
  urlServiceConfig: {
    sportsbook: {
      pattern:
        '/$localizedPath/sportsbook/$categoryRef/$subcategoryName-$subcategoryId/$eventName/:eventId',
      apiPattern: '/bet/category/name/#categoryRef/id/$subcategoryId',
    },
    casino: {
      pattern: '/casino/$productRef/#gameName-:gameId',
      apiPattern: '/casino/$productRef/_gameName?id=:gameId',
    },
    virtual: {
      pattern: '/$localizedPath/virtual/$categoryRef/$eventName/',
      apiPattern: '/bet/category/name/#categoryRef/id/$subcategoryId',
    },
    lotto: {
      pattern: '/$localizedPath/lotto/$categoryRef/$eventName/',
      apiPattern: '/bet/category/name/#categoryRef/id/$subcategoryId',
    },
  },
  blacklistMarkets: '',
  useLocalApps: true,
  customGtmEventNames: {
    REGISTER_START: 'register-start',
    REGISTER_INTERACTION: 'register-interaction',
    REGISTER_STEP_COMPLETED: 'register-step-completed',
    REGISTER_SUBMITTED: 'register-submitted',
    REGISTER_FAILED: 'register-failed',
    REGISTER_COMPLETED: 'register-completed',
    REGISTER_VERIFICATION: 'register-verification',

    LOGIN_STARTED: 'login-started',
    LOGIN_SUBMITTED: 'login-submitted',
    LOGIN_FAILED: 'login-failed',
    LOGIN_SUCCESS: 'login-success',

    BET_ADD_NEW: 'bet-add-new',
    BET_OPEN: 'bet-open',
    BET_SUBMITTED: 'bet-submitted',
    BET_SUCCESS: 'bet-success',
    BET_FAILED: 'bet-failed',

    DEPOSIT_STARTED: 'deposit-started',
    DEPOSIT_SUBMITTED: 'deposit-submitted',
    DEPOSIT_SUCCESS: 'deposit-success',
    DEPOSIT_FAILED: 'deposit-failed',

    WITHDRAWAL_STARTED: 'withdrawal-started',
    WITHDRAWAL_SUBMITTED: 'withdrawal-submitted',
    WITHDRAWAL_SUCCESS: 'withdrawal-success',
    WITHDRAWAL_FAILED: 'withdrawal-failed',

    WELCOME_POP_UP_CROSS: 'welcome-pop-up-cross',
    WELCOME_POP_UP_IMAGE_CLICK: 'welcome-pop-up-image-click',
    WELCOME_POP_UP_DEPOSIT_CLAIM: 'welcome-pop-up-deposit-claim',
  },
  productRestrictionConfig: [
    {
      productGroupRef: 'SPORTSBOOK',
      urlToMatch: '/sportsbook/',
      shouldNotRedirect: true,
    },
    {
      productGroupRef: 'LOTTO',
      urlToMatch: '/lotto/',
    },
    {
      productGroupRef: 'CASINO',
      urlToMatch: '/casino/',
    },
    {
      productGroupRef: 'VIRTUAL',
      urlToMatch: '/virtual/',
    },
  ],
  kyc: {
    config:
      'id:IDENTITY_CARD,id:SOCIAL_SECURITY_NO,id:DRIVING_LICENSE,id:PASSPORT,id:PROOF_OF_IDENTITY,address:UTILITY_BILL,address:BANK_STATEMENT,address:PROOF_OF_ADDRESS',
    required: 'id:1,address:1',
  },
  emptybetslip: {
    features: 'livechat,safergambling',
  },
  lastBets: 1,
  adjustConfig: {
    appToken: '2g49v3ku3d6o',
    eventTokens: {
      bet_success: '6jmdmr',
      deposit_success: '4m3aq4',
      login: '4envqj',
      registration: '6w2twb',
    },
  },
  customerSessionParam: {
    countUnsettledBets: true,
  },
  preventSessionLogoutIniFrame: true,
};
